#navlink{
    font-weight: bold;
}

#navlink a.active {
    color: white !important;
  }

#navlink a:hover {
    color: #ad7cfd !important;
  }

#google_login_button{
  color: #7B2DFD !important;
}


@import url('https://fonts.googleapis.com/css?family=Raleway:300,400');


/* Button Styles */

.navlogbutton {
  display: inline-flex;
  height: 40px;
  width: 150px;
  border: 2px solid #7B2DFD;
  border-radius: 15px;
  color: #6c11ff;
  font-family: 'Azonix';
  font-weight: 700 !important;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.9em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.navlogtext {
  color: #e2e2e2;
  text-decoration: none;
  letter-spacing: 1px;
}


.navlogbutton {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.navlogbutton span {
  position: relative;
  transition: all .45s ease-Out;
}

.translate-login {
  transform: rotate(50deg);
  width: 100%;
  height: 250%;
  left: -200px;
  top: -30px;
  background: #ebebeb;
  position: absolute;
  transition: all .3s ease-Out;
}

.navlogbutton:hover .translate-login {
  left: 0;
}

.navlogbutton:hover span {
  color: #5829ff;
}

.navtogglebtn{
  color: #7B2DFD;
}

.navtogglebtn:hover{
  color: #7B2DFD;
}

.sidebartogglebtn:hover span {
  color: #7B2DFD;
}

.sidebartogglebtn{
  color: #7B2DFD;
}

.sidebartogglebtn:hover{
  color: #7B2DFD;
}