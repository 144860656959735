@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000000;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a:hover {
  cursor : url('https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/cursors/pointer_cMHOnht2qQ.png?updatedAt=1694797738041&ik-s=232f0c15439ba2fde207b67db7b801de45813508'), pointer;
} 


/* Back To Top Button */
#scroll {
  height: 50px;
  z-index: 5;
  position: fixed;
  right: 15px;
  bottom: 20px;
  cursor: pointer;
  text-indent: -9999px;
  display: none;
}

/* fonts setup */
@font-face {
  font-family: 'Proxima Nova';
  src: url('../src/assets/fonts/proximanova.otf');
}

@font-face {
  font-family: 'Monument Extended';
  src: url('../src/assets/fonts/MonumentExtended.otf');
}