
#navlink {
  font-weight: bold;
}

#navlink a {
  color: #7B2DFD !important;
  font-weight: bold;
}

#navlink a.active {
  color: white !important;
}

#navlink a:hover {
  color: #ad7cfd !important;
  transition: all 0.3s ease-in-out;
}

#navlink .dashicon {
  color: #7B2DFD !important;
  fill: #7B2DFD !important;
}

#navlink .dashicon.active {
  color: white !important;
  fill: white !important;
}

#navlink .dashicon:hover {
  color: #ad7cfd !important;
  fill: #ad7cfd !important;
  transition: all 0.3s ease-in-out;
}


/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: auto;
  padding: 1px 16px;
}

@media screen and (max-width: 1030px) {
  div.content {
    margin-left: 0;
    padding: 0px 0px;
  }
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  div.content {
    margin-left: 0;
    padding: 0px 0px;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  div.content {
    margin-left: 0;
    padding: 0px 0px;
  }
}