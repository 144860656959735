.signupsection {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Jost', sans-serif;
    background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
    background-image: url('../../../public/images/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.signuploginmain {
    margin-top: 80px;
    margin-bottom: 30px;
    width: 350px;
    height: 500px;
    background: red;
    overflow: hidden;
    background: url("https://th.bing.com/th/id/OIP.kfbKFI0k1Sr8AarXykgGIwHaNK?pid=ImgDet&rs=1") no-repeat center/ cover;
    border-radius: 10px;
    box-shadow: 5px 20px 50px #000;
}

#chk {
    display: none;
}

.signup {
    position: relative;
    width: 100%;
    height: 100%;
}

.signuploginlabel {
    color: #fff;
    font-size: 2.3em;
    justify-content: center;
    display: flex;
    margin: 60px;
    font-weight: bold;
    cursor: pointer;
    transition: .5s ease-in-out;
}

.signuplogininput {
    width: 60%;
    height: 20px;
    background: #e0dede;
    color: black;
    font-weight: bold;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}

.signuploginbutton {
    width: 60%;
    height: 40px;
    margin: 10px auto;
    justify-content: center;
    display: block;
    color: #fff;
    background: #573b8a;
    font-size: 1em;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: .2s ease-in;
    cursor: pointer;
}

.signuploginbutton:hover {
    background: #6d44b8;
    color: white !important;
}

.login {
    height: 460px;
    background: #eee;
    border-radius: 60% / 10%;
    transform: translateY(-180px);
    transition: .8s ease-in-out;
}

.login .signuploginlabel {
    color: #573b8a;
    transform: scale(.6);
}

.forgotpassword {
    color: #573b8a;
    font-size: 13px;
    margin: 10px auto;
    text-align: center;
    cursor: pointer;
    transition: .2s ease-in;
    justify-content: right;
    margin-right: 70px;
    display: flex;
    font-weight: bold;
}

#chk:checked~.login {
    transform: translateY(-500px);
}

#chk:checked~.login .signuploginlabel {
    transform: scale(1);
}

#chk:checked~.signup .signuploginlabel {
    transform: scale(.6);
}